<template>
    <div class="card mb-4">
        <div class="card-body">
            <template v-if="noticia.textos">
                <img
                    v-if="noticia.banner"
                    class="mb-4 img-fluid text-center"
                    :src="require('@/assets/noticias/' + noticia.banner)"
                    alt="Notica"
                />
                <h6 v-html="noticia.titulo" class="text-dark"></h6>
                <p v-html="noticia.textos[0]" class="text-truncate-custom text-muted"></p>
                <div class="row justify-content-center">
                    <router-link :to="`/comunicado-prensa-noticias/${index}`">
                        <button class="btn btn-outline-success"><i class="fa fa-seach"></i> Ver más</button>
                    </router-link>
                </div>
            </template>
            <template v-else>
                <img
                    class="mb-4 img-fluid text-center"
                    :src="require('@/assets/noticias/' + noticia.images[0])"
                    alt="Notica"
                />
                <div class="row justify-content-center">
                    <button class="btn btn-outline-success" data-toggle="modal" :data-target="`#modal-` + index">
                        <i class="fa fa-seach"></i> Ver más
                    </button>
                </div>
            </template>
        </div>

        <!-- Modal -->
        <div
            class="modal fade bd-example-modal-xl"
            :id="'modal-' + index"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-center">
                        <img
                            v-for="(imagen, index) of noticia.images"
                            :key="index"
                            class="mb-4 img-fluid"
                            :src="require('@/assets/noticias/' + imagen)"
                            alt="Notica"
                        />
                        <template v-if="noticia.links">
                            <a
                                :href="link"
                                v-for="(link, indexLink) of noticia.links"
                                :key="'link-' + indexLink"
                                target="_blank"
                            >
                                {{ link }} <br />
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["noticia", "index"],
};
</script>

<style scoped>
.text-truncate-custom {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
