<template>
    <div>
        <div class="m-4">
            <div v-for="country in countries" class="py-2" :key="country.id">
                <a role="button" @click="setLenguage(country.code)">
                    <img :alt="country.alt" :src="country.flagUrl" class="img-fluid img-custom" />
                    <span class="ml-2">{{ country.title }}</span>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["countries"],
    methods: {
        setLenguage(code) {
            window.doGTranslate(code);
            this.$emit("setLenguage", code);
        },
    },
};
</script>
<style>
.img-custom {
    width: 35px;
}

@media screen and (max-width: 576px) {
    .img-custom {
        width: 50px;
    }
}
</style>
