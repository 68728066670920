<template>
    <div class="bg-gray">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li
                    v-for="(slider, index) of sliders"
                    data-target="#carouselExampleIndicators"
                    :data-slide-to="index"
                    :class="{ active: index == 0 }"
                ></li>
            </ol>
            <div class="carousel-inner">
                <div v-for="(slider, index) of sliders" :class="{ 'carousel-item': true, active: index == 0 }">
                    <img class="img-fluid d-none d-md-block w-100" :src="slider.src" alt="First slide" />
                    <img class="img-fluid d-block d-md-none w-100" :src="slider.src_mobile" alt="First slide" />
                    <div
                        v-if="slider.caption"
                        class="carousel-caption d-none d-md-block"
                        style="text-shadow: 1px 1px 2px black"
                    >
                        <h3>{{ slider.caption }}</h3>
                        <p>{{ slider.text }}</p>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            sliders: [
                {
                    caption: "",
                    text: "",
                    src: require("../assets/slider/slider1.jpg"),
                    src_mobile: require("../assets/slider/slider1-mobile.jpg"),
                },
                {
                    caption: "",
                    text: "",
                    src: require("../assets/slider/slider2.jpg"),
                    src_mobile: require("../assets/slider/slider2-mobile.jpg"),
                },
                {
                    caption: "",
                    text: "",
                    src: require("../assets/slider/slider3.jpg"),
                    src_mobile: require("../assets/slider/slider3-mobile.jpg"),
                },
            ],
        };
    },
};
</script>
<style scoped>
img {
    max-height: 700px !important;
}
.bg-gray {
    background: #bfbfbf;
}

.carousel-caption {
    z-index: 0 !important;
}
</style>
