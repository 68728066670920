<template>
    <div id="app">
        <TopMenu @translate="showTranslate" :isactive="showTranslator" />
        <Translator v-if="showTranslator" @setLenguage="showTranslate" :countries="countriesShow" />
        <div class="mt-n3">
            <router-view />
        </div>
        <Footer />
    </div>
</template>
<script>
import TopMenu from "@/components/TopMenu";
import Footer from "@/components/Footer";
import Translator from "@/components/Translator.vue";

export default {
    data() {
        return {
            showTranslator: false,
            toggle: false,
            countriesShow: [
                {
                    code: "en|en",
                    title: "English",
                    flagUrl: require("./assets/flag-usa.png"),
                },
                {
                    code: "en|es",
                    title: "Spanish",
                    flagUrl: require("./assets/flag-gt.png"),
                },
            ],
        };
    },
    components: {
        TopMenu,
        Footer,
        Translator,
    },
    methods: {
        showTranslate() {
            this.showTranslator = !this.showTranslator;
        },
    },
    mounted() {
        const scripAnalytics = document.createElement("script");
        scripAnalytics.type = "text/javascript";
        scripAnalytics.src = "/js/google-analytics.js";
        document.head.appendChild(scripAnalytics);
    },
};
</script>
