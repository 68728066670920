
let noticias = [
    {
        titulo: 'Transformando Comunidades: El Impacto del Programa en Petén',
        textos: [
            "Guatemala, 24 de febrero 2025.- En el Municipio de Santa Elena, Petén, un pequeño huerto de traspatio está sembrando más que solo hortalizas; está cultivando esperanza. Gracias al Programa MOSCAMED, este espacio ofrece a las familias locales una <b>oportunidad de autoabastecimiento y acceso a alimentos frescos</b>, contribuyendo a la lucha contra la desnutrición infantil en Guatemala.",
            "En un país donde más de 3,200 niños menores de 5 años sufren de desnutrición aguda, según datos más recientes de la Secretaría de Seguridad Alimentaria y Nutricional (SESAN), iniciativas como esta son fundamentales. <b>Los huertos no solo proporcionan alimentos, sino también educación agrícola</b>: los miembros de la comunidad aprenden técnicas de siembra, riego y manejo de cultivos que les permitirán asegurar una dieta más nutritiva y sostenible.",
            "El Programa MOSCAMED, cuyo objetivo principal es la prevención y erradicación de la Mosca del Mediterráneo, desempeña un papel crucial no solo en la protección de los cultivos agrícolas, utilizando tecnología de punta amigable con el ambiente, sino también en la mejora de la seguridad alimentaria de las comunidades. A través de acciones de control biológico y monitoreo, <b>el Programa contribuye a mantener las cosechas libres de plagas</b>, asegurando así que los huertos de traspatio puedan prosperar de manera sostenible.",
            "Este esfuerzo va más allá de la simple intervención técnica; al integrar estrategias de manejo integrado de plagas, MOSCAMED ayuda a las comunidades a fortalecer su capacidad productiva, <b>promoviendo un entorno seguro y saludable para el cultivo de alimentos frescos</b>, vitales para la nutrición familiar.",
            "Con cada huerto sembrado y cada técnica compartida, el Programa MOSCAMED demuestra que la colaboración y el conocimiento compartido son claves para transformar las realidades más desafiantes en Guatemala. Un huerto no solo da frutos, sino también un futuro más próspero y seguro para las familias guatemaltecas.",
            "<h5 class='text-center mt-5'>Porque en MOSCAMED, somos los frutos de un esfuerzo compartido.</h5>",
        ],
        fecha: '2025-02-24',
        banner: "noticia-35.jpg",
        images: ["noticia-351.jpg", "noticia-352.jpg"],
    },
    {
        titulo: '<b>Juntos por un Futuro sin Plaga:</b> El Impacto del Programa en Barranca Grande',
        images: ["noticia-34.jpg"],
    },
    {
        titulo: 'El IICA y el Programa MOSCAMED presentan los resultados de estudios sobre "Polinización Cruzada y Desafricanización de las Abejas".',
        images: ["noticia-33.jpg", "noticia-33B.jpg"],
    },
    {
        titulo: '<b>Fortaleciendo Alianzas:</b> MOSCAMED y las Comunidades de Todos Santos Cuchumatán en la Lucha Contra la Mosca del Mediterráneo',
        images: ["noticia-32.jpg"],
    },
    {
        titulo: null,
        images: ["noticia-31.jpg"],
    },
    {
        titulo: null,
        images: ["noticia-29.jpg", "noticia-29B.jpg"],
    },
    {
        titulo: null,
        images: ["noticia-28.jpg"],
    },
    {
        titulo: null,
        images: ["noticia-27.jpg", "noticia-27B.jpg"],
    },
    { titulo: null, images: ["noticia-24.jpg"] },
    { titulo: null, images: ["noticia-10.jpg"] },
    { titulo: null, images: ["noticia-25.jpg"] },
    {
        titulo: null,
        images: ["noticia-23.jpg"],
        links: ["https://www.11isffei.com/"],
    },
    { titulo: null, images: ["noticia-22.jpg"] },
    { titulo: null, images: ["noticia-21.jpg"] },
    {
        titulo: null,
        images: ["noticia-20.jpg"],
        links: [
            "https://visar.maga.gob.gt/?p=19396",
            "https://www.maga.gob.gt/melocoton-guatemalteco-logra-admisibilidad-en-el-mercado-de-mexico/",
        ],
    },
    { titulo: null, images: ["noticia-19.jpg"] },
    { titulo: null, images: ["noticia-18.jpg"] },
    {
        titulo: null,
        images: ["noticia-17.jpg"],
        links: [
            "https://www.facebook.com/100064838012442/posts/pfbid02HbrTFrEEd7R8TmWiqVXGC48NiEzkYkYVwSnx36EKzibejdZpeiwxuzfk47tCBpgl/",
            "https://www.facebook.com/congreso.republica.guatemala/videos/429917539205622",
            "https://www.congreso.gob.gt/noticias_congreso/9045/2022/4",
        ],
    },
    // {imagen: 'noticia-15.jpg'},
    { titulo: null, images: ["noticia-16.jpg"] },
    { titulo: null, images: ["noticia-16.1.jpg"] },
    { titulo: null, images: ["noticia-14.jpg"] },
    { titulo: null, images: ["noticia-13.jpg"] },
    { titulo: null, images: ["noticia-11.jpg"] },
    { titulo: null, images: ["noticia-12.jpg"] },
    { titulo: null, images: ["noticia-9.jpg"] },
    { titulo: null, images: ["noticia-8.jpg"] },
    { titulo: null, images: ["noticia-7.jpg"] },
    { titulo: null, images: ["noticia-1.jpg"] },
    { titulo: null, images: ["noticia-2.jpg"] },
    { titulo: null, images: ["noticia-3.jpg"] },
    { titulo: null, images: ["noticia-4.jpg"] },
    { titulo: null, images: ["noticia-5.jpg"] },
    { titulo: null, images: ["noticia-6.jpg"] },
];
export const noticiasMixin = {
    methods: {
        getNoticias() {
            return noticias;
        },
        lastNoticia() {
            return noticias[0];
        },
        getNoticia(index) {
            return noticias[noticias.length - index];
        },

        takeNoticias(count = 4) {
            return noticias.slice(0, count);
        },
    },
};
