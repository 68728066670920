<template>
    <div class="home">
        <Carousel />
        <div class="container">
            <div class="row mt-5 mb-5">
                <div class="col-sm-3 text-center">
                    <router-link to="medfly">
                        <h5 class="fon-size"><strong class="green">MOSCA DEL MEDITERRÁNEO</strong></h5>
                        <img class="mt-2 pb-3 img-fluid" src="../assets/home/mosca.jpg" alt="" />
                    </router-link>
                </div>
                <div class="col-sm-3 text-center">
                    <router-link to="tecnica">
                        <h5 class="fon-size"><strong class="green">TÉCNICA DEL INSECTO ESTÉRIL</strong></h5>
                        <img class="mt-2 pb-3 img-fluid" src="../assets/home/tecnica.jpg" alt="" />
                    </router-link>
                </div>
                <div class="col-sm-3 text-center">
                    <router-link to="/apicultura">
                        <h5 class="fon-size"><strong class="green">APOYO A LA APICULTURA</strong></h5>
                        <img class="mt-2 pb-3 img-fluid" src="../assets/home/apicola-1.jpg" alt="" />
                    </router-link>
                </div>
                <div class="col-sm-3 text-center">
                    <router-link to="fruticultura">
                        <h5 class="fon-size"><strong class="green">PROTECCIÓN DE LA FRUTICULTURA</strong></h5>
                        <img class="mt-2 pb-3 img-fluid" src="../assets/home/papaya.jpg" alt="" />
                    </router-link>
                </div>
            </div>
            <h6><strong>COMUNICADOS DE PRENSA Y NOTICIAS</strong></h6>
            <hr />
            <div class="row pt-2">
                <div v-for="(noticia, index) in noticias" :key="index" class="col-6 col-sm-3">
                    <NewCard :noticia="noticia" :index="getNoticias().length - index" />
                </div>

                <div class="col-12">
                    <router-link to="/comunicado-prensa-noticias"
                        ><p class="pt-2 text-success"><strong>VER MÁS COMUNICADOS</strong></p></router-link
                    >
                </div>
            </div>
        </div>
        <div class="bg-beige">
            <div class="container">
                <h6 class="pt-3"><strong>VIDEOS</strong></h6>
                <hr />
                <div class="row">
                    <div class="col-sm-6 text-center">
                        <iframe
                            class="size"
                            :src="video.link"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </div>
                    <div class="col-sm-6 pt-5 mt-4">
                        <p class="text-justify p-5">
                            <strong>{{ video.tilulo }}</strong>
                        </p>
                    </div>
                    <div class="col">
                        <router-link to="videos"
                            ><p class="pt-2 text-success"><strong>VER MÁS VIDEOS</strong></p></router-link
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-beige">
            <div class="container">
                <h6 class="pt-3"><strong>PODCAST</strong></h6>
                <hr />
                <div class="row">
                    <div class="col-sm-6 text-center">
                        <img class="mt-2 img-fluid" src="@/assets/podcast/portada.png" alt="podcast" />
                    </div>
                    <div class="col-sm-6 pt-5 mt-4">
                        <p class="text-justify p-5"><strong></strong></p>
                    </div>
                    <div class="col">
                        <router-link to="podcast"
                            ><p class="pt-2 text-success"><strong>VER MÁS</strong></p></router-link
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row text-center pt-5 pb-5">
                <div class="col-sm-3">
                    <router-link to="informacionpublica">
                        <img class="mt-2 img-fluid" src="../assets/logos/informacionenlinea.jpg" alt="" />
                        <p class="pt-1"><strong>INFORMACIÓN PÚBLICA</strong></p>
                    </router-link>
                </div>
                <div class="col-sm-3">
                    <router-link to="faqs">
                        <img class="mt-4 img-fluid" src="../assets/logos/question.png" alt="" /><br /><br />
                        <p><strong>PREGUNTAS FRECUENTES</strong></p>
                    </router-link>
                </div>
                <div class="col-sm-3">
                    <router-link to="centroContacto">
                        <img class="mt-4 img-fluid" src="../assets/logos/envelop.png" alt="" />
                        <p class="pt-4"><strong>CENTROS DE CONTACTO</strong></p>
                    </router-link>
                </div>
                <div class="col-sm-3">
                    <router-link to="/publicaciones">
                        <img class="mt-2 img-fluid" src="../assets/logos/publicaciones.png" alt="" />
                        <p class="pt-4"><strong>PUBLICACIONES</strong></p>
                    </router-link>
                </div>
            </div>
            <div class="row justify-content-center pb-5">
                <div class="col-sm-3 pb-4">
                    <router-link to="eventos/invitacion/oferta">
                        <div class="pt-4 text-center" style="color: #006617">
                            <img class="mt-2 img-fluid" src="@/assets/logos/invitacion.png" alt="" />
                            <h5 class="text-center pt-4 text-uppercase">
                                <strong
                                    >INVITACIONES <br />
                                    A OFERTAR</strong
                                >
                            </h5>
                        </div>
                    </router-link>
                </div>
                <div class="col-sm-3 pb-4">
                    <router-link to="eventos/actividades">
                        <div class="pt-4 text-center" style="color: #006617">
                            <img class="mt-2 img-fluid" src="@/assets/logos/actividades.png" alt="" />
                            <h5 class="text-center pt-4 text-uppercase">
                                <strong
                                    >Actividades <br />
                                    relevantes</strong
                                >
                            </h5>
                        </div>
                    </router-link>
                </div>
                <div class="col-sm-3 pb-4">
                    <router-link to="eventos/invitacion/empleo">
                        <div class="pt-4 text-center" style="color: #006617">
                            <img class="mt-2 img-fluid" src="@/assets/logos/empleo.png" alt="" />
                            <h5 class="text-center pt-4 text-uppercase"><strong>OPORTUNIDADES DE EMPLEO</strong></h5>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import NewCard from "@/components/NewCard.vue";
import { videosMixin } from "@/mixins/Videos";
import { noticiasMixin } from "@/mixins/Noticias";

export default {
    name: "Home",
    mixins: [videosMixin, noticiasMixin],
    data() {
        return {
            video: this.lastVideo(),
            noticias: this.takeNoticias(),
        };
    },
    components: {
        Carousel,
        NewCard,
    },
};
</script>

<style scoped>
hr {
    background: #2b81b6 !important;
}

a {
    color: black;
}

a:hover {
    color: black;
    text-decoration: none;
}
h5 {
    font-size: 22px;
}

.bg-beige {
    background: #dfd8c5;
}
.green {
    color: #546536;
}

.text-blue {
    color: #0075bb;
}

.size {
    width: 540px;
    height: 300px;
}

@media (max-width: 767px) {
    .size {
        width: 320px;
        height: 300px;
    }
}

@media (max-width: 1000px) {
    .fon-size {
        font-size: 17px !important;
    }
}

@media (max-width: 995px) {
    .fon-size {
        font-size: 14px !important;
    }
}
</style>
