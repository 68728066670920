import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import "./scss/app.scss";
import moment from "moment";

Vue.config.productionTip = false;
Vue.prototype.$currentYear = new Date().getFullYear();
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)

//filters
Vue.filter('date', function (value, format = 'DD/MM/Y') {
    return new moment(value).format(format);
})

Vue.component("SectionHeader", require("@/components/SectionHeader").default);
Vue.component("SectionTitle", require("@/components/SectionTitle").default);

new Vue({
  router,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
